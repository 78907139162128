import {Button, CheckBox, Icon, Modal, Skeleton, Text} from "components";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {checkPriceAvailability, convertDate} from "utils";
import {SellOffProductsContext} from "..";

import {NotificationManager} from "common/notifications";
import {useToggleState} from "hooks";
import {cloneDeep, isEmpty} from "lodash";
import {SalesService} from "types";
import {useSearchParams} from "react-router-dom";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
  productId?: string;
};

const StartPreparationPhaseModal = ({
  isOpen,
  toggle,
  getData,
  productId,
}: Props) => {
  const [serchParams] = useSearchParams();
  const {item: sellOffProductITem} = useContext(SellOffProductsContext);
  const [loading, setLoading] = useToggleState();
  const [startPhaseLoading, setStartPhaseLoading] = useToggleState();
  const [startPrePhaseData, setStartPrePhaseData] =
    useState<SalesService.StartPhaseSellOffProductRequirementDto>();

  const id = sellOffProductITem?.id || productId;
  const channelCode =
    sellOffProductITem?.channelCode || serchParams.get("channelCode");

  const preparationCheckList = () => {
    if (!id) return;
    setLoading();
    const url = URLS.PREPARATION_PHASE_START_REQUIREMENT_URL(id, channelCode);
    ServiceApi.get(url)
      .then(({data}) => {
        setStartPrePhaseData(data);
      })
      .finally(() => {
        setLoading();
      });
  };

  const onChangeHandler = (listId: string) => {
    setLoading();
    const url = URLS.PREPARATION_PHASE_CHECKLIST_URL(id, channelCode);
    const body = {preparationPhaseCheckListItemId: listId};
    ServiceApi.patch(url, body)
      .then(({data}) => {
        // getData();
        preparationCheckList();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setLoading();
      })
      .catch(() => setLoading());
  };

  const startPhase = () => {
    setStartPhaseLoading();
    const url = URLS.START_PHASE_URL(id, channelCode);
    ServiceApi.post(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.start-phase-msg",
          "global.toast.start-phase-title",
        );
        setStartPhaseLoading();
        getData();
        toggle();
      })
      .catch(() => {
        setStartPhaseLoading();
      });
  };

  useEffect(() => {
    if (isOpen) {
      preparationCheckList();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            salesManagment.sellOffSales.sellOffProduct.startSelloffProccess
          </Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-4 overflow-auto">
        <>
          <div className="flex flex-col gap-y-2 text-body-2 font-medium text-gray-500">
            <h6 className="text-heading-6">
              {startPrePhaseData?.pimProduct?.originalName}
            </h6>
            <p>
              <Text>
                salesManagment.sellOffSales.sellOffProduct.articleNumber
              </Text>
              #{startPrePhaseData?.pimProduct?.articleNumber}
            </p>
          </div>
          <div className="grid grid-cols-11 gap-2">
            <div className="col-span-5 space-y-4 rounded bg-gray-100 p-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.currentPhase
                </Text>
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.preparation
                </Text>
              </p>
              <p>
                {checkPriceAvailability(
                  startPrePhaseData?.pimProduct?.vk1Price?.amount,
                )}
              </p>
            </div>
            <div className="flex-center flex">
              <Icon icon="arrow-right" />
            </div>
            <div className="col-span-5 space-y-4 rounded bg-gray-100 p-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.nextPhase
                </Text>
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                {startPrePhaseData?.firstPhaseTitle}
              </p>
              <p className="text-body-2 font-medium text-gray-800">
                0/{startPrePhaseData?.firstPhaseTotalDays}{" "}
                <Text>salesManagment.sellOffSales.sellOffProduct.days</Text>
              </p>
              <div className="space-x-2 text-body-2">
                <span className="text-gray-500 line-through">
                  {checkPriceAvailability(
                    startPrePhaseData?.pimProduct?.vk1Price?.amount,
                  )}
                </span>
                <span className="text-danger">
                  %{startPrePhaseData?.firstPhaseDiscount}
                </span>
                <span className="text-heading-6 font-semibold text-primary-active">
                  {checkPriceAvailability(startPrePhaseData?.firstPhasePrice)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 text-body-2 font-medium text-gray-500">
            <h6 className="text-heading-6">
              <Text>salesManagment.sellOffSales.sellOffProduct.checkList</Text>
            </h6>
            <p>{startPrePhaseData?.preparationPhase?.description}</p>
          </div>
          {loading && isEmpty(startPrePhaseData) && (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          )}
          <div className="grid gap-4">
            {startPrePhaseData?.preparationPhase?.checkListItems?.map(
              (list: SalesService.CheckListItemQueryDto, index: number) => {
                return (
                  <>
                    {loading ? (
                      <Skeleton.Input />
                    ) : (
                      <div
                        className="flex items-center space-x-4 rounded bg-primary-light p-4"
                        key={list.label}
                      >
                        <div>
                          <CheckBox
                            setValue={checked => {
                              let clone = cloneDeep(
                                startPrePhaseData?.preparationPhase
                                  ?.checkListItems,
                              );
                              if (clone) {
                                clone[index].isChecked = checked;
                                //@ts-ignore
                                onChangeHandler(list.checkListItemId as string);
                              }
                            }}
                            value={list.isChecked as boolean}
                            className="bg-white"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <span className="text-heading-5 font-normal text-gray-700">
                            {list.label}
                          </span>
                          <p className="text-body-2">
                            {list.updatedBy} {convertDate(list.updatedAt)}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                );
              },
            )}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          className="mr-2"
          onClick={toggle}
          variant="white"
          disabled={loading}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
        </Button>
        <Button
          type="button"
          onClick={startPhase}
          loading={startPhaseLoading}
          disabled={
            !startPrePhaseData?.preparationPhase ||
            startPrePhaseData?.preparationPhase?.checkListItems
              ?.map(item => item.isChecked)
              .includes(false)
          }
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.start</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default StartPreparationPhaseModal;
