import {withPermission} from "hoc";
import {useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {SuppliersContext} from "..";
import Details from "./program/details";
import ProgramList from "./program/program-list";

function Program() {
  const {suppliersData} = useContext<any>(SuppliersContext);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [programId, setProgramId] = useState<string | undefined>("");
  const [isOpenCreateDrawer, toggleCreateDrawer] = useToggleState();

  const getProgaramList = () => {
    setLoading(true);
    const urlPrograms = URLS.GET_SUPPLIER_PROGRAMS(suppliersData.supplierId);
    ServiceApi.get(urlPrograms)
      .then(({data}) => {
        setData(data?.length !== 0 ? data : []);
        if (data?.length !== 0) setProgramId(data[0].id);
        else setProgramId(undefined);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getProgaramList, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex items-start gap-6">
      <div>
        <ProgramList
          programs={data}
          loading={loading}
          selectedId={programId}
          setSelectedId={setProgramId}
          updateList={getProgaramList}
          suppliersData={suppliersData}
          isOpenCreateDrawer={isOpenCreateDrawer}
          toggleCreateDrawer={toggleCreateDrawer}
        />
      </div>
      <div className="flex flex-1">
        <Details
          programId={programId}
          setProgramId={setProgramId}
          supplierData={suppliersData}
          getData={getProgaramList}
          toggle={toggleCreateDrawer}
        />
      </div>
    </div>
  );
}
export default withPermission(Program, ["PS_GetSupplier"]);
