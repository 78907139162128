import {Loading} from "components";
import {useMountEffect, useSelector, useUpdateEffect} from "hooks";
import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Outlet} from "react-router";
import {ServiceApi, URLS} from "services";
import {setPermissions} from "store/permissions";
import {setProfile} from "store/profile";
import {DomHandler} from "utils";
import {Header} from "..";
import Sidebar from "../sidebar/sidebar";
import {createClassNames} from "./classname";
import {useMsal} from "@azure/msal-react";
import {getLocalIdToken} from "services/service-api";
import NoAccess from "no-access";

import {useLocation} from "react-router-dom";

const MainLayout = () => {
  const location = useLocation();
  const {i18n} = useTranslation();

  const {instance, accounts} = useMsal();

  const companySetting = useSelector(s => s.companySetting);

  const dispatch = useDispatch();

  const documentWidth = DomHandler.getClientWidth(document.body);

  const [isActive, setIsActive] = useState<boolean>(
    documentWidth > 768 ? true : false,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isB2BUser, setIsB2BUser] = useState<boolean>(true);

  const {main} = createClassNames(isActive);

  const logoutHandler = async () => {
    const token = getLocalIdToken();
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId,
    );
    await instance.logoutRedirect({
      idTokenHint: token.secret,
      account: currentAccount,
      postLogoutRedirectUri: "/",
    });
    localStorage.clear();
  };

  useMountEffect(() => {
    setLoading(true);
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId,
    );
    const userType = currentAccount?.idTokenClaims?.extension_usertype;
    if (userType === 4 || userType === 8) {
      const getProfile = ServiceApi.get(URLS.B2B_USER_URL);
      const getPermissions = ServiceApi.get(URLS.GET_USER_PERMISSIONS_URL);
      const requests = [getProfile, getPermissions];
      Promise.all(requests)
        .then(([profile, permissions]) => {
          dispatch(setProfile(profile.data));
          dispatch(setPermissions(permissions.data.permissions));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setIsB2BUser(false);
      setLoading(false);
      console.log();
    }
  });

  useUpdateEffect(() => {
    if (companySetting) {
      i18n.changeLanguage(companySetting.defaultDashboardLanguage);
    }
  }, [companySetting]);

  useEffect(() => {
    if (documentWidth < 769) {
      setIsActive(false);
    }
  }, [location]);

  return (
    <>
      {!loading && (
        <>
          {isB2BUser ? (
            <>
              <Sidebar isActive={isActive} setIsActive={setIsActive} />
              <main className={main}>
                <div className="text- min-h-screen pb-20">
                  <Header />
                  <div className="container px-8 pt-6">
                    <Outlet />
                  </div>
                </div>
              </main>
            </>
          ) : (
            <NoAccess onClick={logoutHandler} />
          )}
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default MainLayout;
