import {NotificationManager} from "common/notifications";
import {
  Button,
  Footer,
  Form,
  Icon,
  Skeleton,
  Tabs,
  Text,
  Toggler,
  WithPermission,
  Wrapper,
} from "components";
import {useConverter, useDataState, usePermission, useTranslate} from "hooks";
import {
  FormEvent,
  createContext,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import {Link} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AvailabilityStatus from "./tabs/availability-status";
import Channels from "./tabs/channels";
import Configuration from "./tabs/configuration";
import General from "./tabs/general";
import PriceCalculation from "./tabs/priceCalculation";

export const ProgramContext = createContext<any>({});
function Details(props: any) {
  const formRef = useRef<any>();
  const {convertDate} = useConverter();
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedOptionsBrand, setSelectedOptionsBrand] = useState<any>();
  const updatePermission = usePermission("PS_UpdateSupplier");
  const translate = useTranslate();

  const getData = (id: string) => {
    setLoading(true);
    setLoadingButton(true);
    const url = URLS.GET_PROGRAM_DETAILS(id);
    ServiceApi.get(url).then(({data}) => {
      if (Object.is(data?.shipping, null)) {
        data.hasShippingInfo = props?.supplierData?.shipping
          ?.minimumShippingDuration
          ? true
          : false;
      } else {
        data.hasShippingInfo = false;
      }
      if (Object.is(data?.bankInfo, null)) {
        data.hasBankInfo = props?.supplierData?.bankInfo ? true : false;
      } else {
        data.hasBankInfo = false;
      }
      setData(data);
      setBaseData(data);
      getBrands(data?.brandId);
    });
  };

  const getBrands = (brandId: any) => {
    if (brandId) {
      const urlBrand = URLS.GET_BRAND_DETAILS_URL(brandId);
      ServiceApi.get(urlBrand).then(({data}) => {
        setSelectedOptionsBrand({...data, label: data.name, value: data.id});
        setLoading(false);
        setLoadingButton(false);
      });
    } else {
      setSelectedOptionsBrand(null);
      setLoading(false);
      setLoadingButton(false);
    }
  };

  const onSelectProgram = () => {
    if (isChanged) {
      const response = window.confirm(`${translate("global.promptMassage")}`);
      if (response) {
        getData(props.programId);
      } else {
        props.setProgramId(data.id);
      }
    } else {
      getData(props.programId);
    }
  };

  useEffect(() => {
    if (props.programId && props.programId !== data.id) onSelectProgram();
  }, [props?.programId]);

  const tabs = [
    {
      label: "productManagement.masterData.suppliers.generalTab",
      component: General,
      ariaLabel: "general",
    },
    {
      label: "priceEngine.calculationLevel.priceCalculationUnits",
      component: PriceCalculation,
    },
    {
      label: "productManagement.masterData.suppliers.configurationTab",
      component: Configuration,
      ariaLabel: "configuration",
    },
    {
      label: "productManagement.masterData.suppliers.availabilityStatusTab",
      component: AvailabilityStatus,
      ariaLabel: "availability-status",
    },
    {
      label: "productManagement.masterData.suppliers.channels",
      component: Channels,
      ariaLabel: "channels",
    },
  ];

  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    const id = formData?.id;
    if (!id) return;
    const url = URLS.UPDATE_PROGRAM(id);
    if (formData?.hasShippingInfo) {
      formData.shipping = {
        durationUnit: props?.supplierData?.shipping?.durationUnit || 1,
        minimumShippingDuration:
          props?.supplierData?.shipping?.minimumShippingDuration,
      };
    } else {
      if (
        formData?.shipping?.minimumShippingDuration === null ||
        formData?.shipping?.minimumShippingDuration === ""
      ) {
        formData.shipping = {durationUnit: formData?.shipping?.durationUnit};
      }
      if (formData?.shipping?.durationUnit === null) {
        formData.shipping = {
          minimumShippingDuration: formData?.shipping?.minimumShippingDuration,
        };
      }
    }
    if (formData.hasBankInfo) {
      formData.bankInfo = {
        bankName: props?.supplierData?.bankInfo?.bankName,
        iban: props?.supplierData?.bankInfo?.iban,
        accountOwnerName: props?.supplierData?.bankInfo?.accountOwnerName,
        bic: props?.supplierData?.bankInfo?.bic,
      };
    }
    ServiceApi.put(url, formData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData(formData.id);
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <div className="flex flex-1 items-center justify-center">
      {props.programId !== undefined && (
        <Form
          ref={formRef}
          onSubmit={onSubmitForm}
          className="flex-1 space-y-6"
        >
          <Tabs activeKey={tabs[0].label}>
            <Wrapper className="w-full">
              <Wrapper.Body className="flex items-center justify-between gap-8">
                {loading ? (
                  <Skeleton.Input />
                ) : (
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {data.title}
                    </span>
                    <p className="text-body-2 font-medium">{data?.code}</p>
                    <p className="text-body-2 font-medium">
                      <Text>
                        productManagement.masterData.suppliers.version
                      </Text>{" "}
                      : {data?.version}
                    </p>
                    <p className="text-body-2 font-medium">
                      <Text>
                        productManagement.masterData.suppliers.lastModifiedAt
                      </Text>{" "}
                      : {convertDate(data?.lastModifiedAt)}
                    </p>
                    <p className="text-body-2 font-medium">
                      <Text>
                        productManagement.masterData.suppliers.lastModifiedBy
                      </Text>{" "}
                      : {data?.lastModifiedByName}
                    </p>
                  </div>
                )}
              </Wrapper.Body>
              <Wrapper.Footer className="!py-0">
                <Tabs.ButtonGroup>
                  {tabs.map(e => (
                    <Tabs.Button key={e.label} eventKey={e.label}>
                      <Text>{e.label}</Text>
                    </Tabs.Button>
                  ))}
                </Tabs.ButtonGroup>
              </Wrapper.Footer>
            </Wrapper>
            {tabs.map(e => (
              <ProgramContext.Provider
                value={{
                  loading: loading,
                  data: data,
                  setData: setData,
                  supplierData: props.supplierData,
                  selectedOptionsBrand: selectedOptionsBrand,
                  setSelectedOptionsBrand: setSelectedOptionsBrand,
                }}
              >
                <Tabs.Item key={e.label} eventKey={e.label}>
                  {createElement(e.component)}
                </Tabs.Item>
              </ProgramContext.Provider>
            ))}
          </Tabs>
          <WithPermission permissions={["PS_UpdateSupplier"]}>
            <Footer show={!isChanged} className="z-20">
              <Button
                as={Link}
                to={"/productManagement/master-data/suppliers"}
                variant="white"
              >
                <Text>global.buttons.discard</Text>
              </Button>
              <Button
                type="button"
                onClick={submit}
                variant="primary"
                disabled={!isChanged || loading}
                loading={loadingButton || loadingButton}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Footer>
          </WithPermission>
        </Form>
      )}
      {props.programId === undefined && (
        <div className="space-y-3 text-center">
          <p className="text-heading-5 font-semibold text-gray-800">
            <Text>productManagement.masterData.suppliers.noProgram</Text>
          </p>
          <p className="text-base text-gray-500">
            <Text>productManagement.masterData.suppliers.canAddProgram</Text>
          </p>
          {updatePermission && (
            <Button
              type="button"
              size="sm"
              onClick={props.toggle}
              aria-label="cy-product-add-button"
            >
              <Icon icon={"plus"} className="mr-2 after:!opacity-100" />
              <Text>productManagement.masterData.suppliers.addProgram</Text>
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default Details;
