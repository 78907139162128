import {Text, WithPermission} from "components";
import {Fragment} from "react";
import RecentlySoldSellOffProducts from "./RecentlySoldSellOffProducts";
import SellOffProducts from "./SellOffProducts";
import SoldSellOffProducts from "./SoldSellOffProducts";

export default function Dashboard() {
  return (
    <Fragment>
      <h3 className="mb-2 text-gray-800">
        <Text>dashboard.title</Text>
      </h3>
      <p className="mb-4 text-gray-800">
        <Text>dashboard.subTitle</Text>
      </p>
      <section className="grid grid-cols-1 gap-6 xl:grid-cols-3">
        {/* <TotalPimProducts /> */}
        {/* <RecentActivity /> */}
        {/* <RecentlyAdded /> */}
        {/* <ProductsCompleteness /> */}
        {/* <PublishedProducts /> */}
        <WithPermission permissions={["PS_GetSellOffProduc"]}>
          <RecentlySoldSellOffProducts />
          <SoldSellOffProducts />
          <SellOffProducts />
        </WithPermission>
      </section>
    </Fragment>
  );
}
