export const global = {
  builtIn: "Built-In DE",
  noAccessText: "Sie haben keinen Zugang zum B2B Dashboard",
  pleaseLoginAccount: "Bitte nutzen Sie einen anderen Account",
  lgoinToDashboard: "Login mit einem anderen Account",
  items: "Artikel",
  home: "STARTSEITE",
  addNew: "Neu hinzufügen",
  details: "Detail",
  none: "none",
  user: "Benutzer",
  admin: "Administrator",
  uploadFile: "Datei hochladen",
  dragDropFile: "Dateien hier per Drag & Drop ablegen",
  required: "erforderlich",
  default: "Standard",
  standard: "Standard",
  editImage: "Bild bearbeiten",
  changeLogo: "Logo ändern",
  fromCamera: "Kamera nutzen",
  chooseFile: "Datei auswählen",
  uploadNewImage: "Neues Bild hochladen",
  save: "Speichern",
  cancel: "Abbrechen",
  discard: "Verwerfen",
  notRequired: "Optional",
  noItems: "Keine Daten verfügbar",
  noItemsElse: "Bitte versuchen Sie etwas anderes",
  areYouSureYouWantToDelete: "Möchten Sie diese Daten wirklich löschen?",
  youCantUndoThis: "Dies kann nicht rückgängig gemacht werden.",
  validation: "Validieren",
  all: "Alles",
  from: "von",
  to: "an",
  edit: "bearbeiten",
  select: "Auswahl",
  noName: "ohne Namen",
  days: "Tage",
  promptMassage: "Sollen die Änderungen verworfen werden? ",
  noData: "Keine daten",
  placeholders: {
    search: "Suchen...",
  },
  buttons: {
    view: "Details anzeigen",
    discard: "Verwerfen",
    delete: "Löschen",
    saveChanges: "Änderungen speichern",
    cancel: "Abbrechen",
    close: "Schließen",
    ImNotSure: "Ich bin mir nicht sicher",
    next: "Weiter",
    back: "Zurück",
    submit: "Absenden",
    create: "Erstellen",
    copy: "Kopieren",
    details: "Detail",
    edit: "Bearbeiten",
    actions: "Aktionen",
    invite: "Einladen",
    disable: "Deaktivieren",
    enable: "Aktivieren",
    selectAll: "Alle auswählen",
    deselectAll: "Alle abwählen",
    setAsCoverPhoto: "Als Titelbild verwenden",
    coverPhoto: "Titelbild",
    approve: "überprüft",
    filters: "Filter",
    resetFilters: "Filter zurücksetzen",
    reset: "Zurücksetzen",
  },
  locations: {
    street: "Straße",
    houseNumber: "Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    state: "Bundesland",
    location: "Standort",
    locationOnMap: "Standort auf Karte",
    address: "Adresse",
    searchPlaceHolder: "Adressensuche",
    name: "Name",
    invalidAddress: "ungültiger Adresse - da wohnt niemand",
    changedAddress:
      "Die Adresse wurde geändert, bitte überprüfen, bevor der Vorgang angeschlossen wird",
    addressValidation: "Addressen-Check",
  },
  toast: {
    "delete-msg": "Objekt wurde erfolgreich gelöscht",
    "delete-title": "Objekt wurde gelöscht",
    "add-msg": "Objekt wurde erfolgreich gespeichert.",
    "add-title": "Objekt hinzugefügt",
    "edit-msg": "Objekt wurde erfolgreich aktualisiert.",
    "edit-title": "Objekt wurde aktualisiert",
    "copy-msg": "Element erfolgreich kopiert",
    "copy-title": "Element kopieren",
    "start-phase-msg": "Phase erfolgreich gestartet",
    "start-phase-title": "Phase starten",
    "change-phase-msg": "Phase erfolgreich ändern",
    "change-phase-title": "Phase ändern",
    "close-phase-msg": "Der Vorgang wurde erfolgreich abgeschlossen",
    "close-phase-title": "Phase schließen",
    "sold-msg": "Erfolgreich verkauft",
    "sold-title": "Verkauft",
    "invite-msg": "Element erfolgreich einladen",
    "invite-title": "Element einladen",
    "publish-msg": "Element erfolgreich veröffentlichen",
    "publish-title": "Element veröffentlichen",
    "unpublish-msg": "Element erfolgreich ausblenden",
    "unpublish-title": "Element ausblenden",
    "import-msg": "Element erfolgreich importieren",
    "import-title": "Element importieren",
    "duplicate-msg": "Produkt-Klon erstellt",
    "duplicate-title": "Name Produkt-Klon",
    "add-default-language-value": "Wert für Sprachausgabe festglegen",
    "print-request-sent": "Druckausgabe erfolgreich gesendet",
    "print-request-sent-title": "Druckausgabe",
  },
  types: {
    infoPageLayout: {
      bannerOne: "Banner eins",
      bannerTwo: "Banner zwei",
      bannerThree: "Banner drei",
      introduction: "Einführung",
      products: "Produkte",
      scrollbar: "Scrollbar",
    },
    position: {
      banner: "Banner",
      carousel: "Karussell",
    },
    mobileAppLink: {
      none: "Keine",
      infoPage: "Infoseite",
      externalLink: "Externer Link",
      channelProduct: "Kanalprodukt",
      button: "Button DE",
    },
    program: {
      none: "Kein Programm",
      manual: "Manuell",
      configurable: "Konfigurierbar",
    },
  },
  channelMessage: {
    status: "Status",
    updatedAt: "Updated amE",
    channel: "Kanal",
    createdAt: "erstellt",
    data: "DatenE",
    outData: "Out Data",
    details: "Details",
  },
  gender: {
    rather: "keine Zuordnung",
    male: "männlich",
    female: "weiblich",
  },
  dayTypes: {
    workDay: "Arbeitstage",
    calendarDay: "Kalendertage",
  },
};
