import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {
  Breadcrumb,
  SearchBox,
  NoItems,
  Skeleton,
  DatePicker,
  Table,
  Button,
  Text,
  Pagination,
  Icon,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {SalesService} from "types";
import DetailsDrawer from "./details";
import {saveAs} from "file-saver";
interface Params {
  /** keyword */
  keyword?: string;
  /**
   * fromDate
   * @format date-time
   */
  fromDate?: string;
  /**
   * toDate
   * @format date-time
   */
  toDate?: string;
  /**
   * orderId
   * @format uuid
   */
  orderId?: string;
  /** channelCode */
  channelCode?: string;
  /**
   * pageNumber
   * @format int32
   */
  pageNumber?: number;
  /**
   * pageSize
   * @format int32
   */
  pageSize?: number;
  /**
   * sortBy = DESC or ASC
   * @default "DESC"
   */
  sortBy: string;
}

const LineItemType = [
  "PimProduct",
  "IwofurnProduct",
  "ManualProduct",
  "ProductService",
  "ManualBundle",
  "PimBundle",
  "PimBundleItem",
  "SellOffProduct",
  "SellOffBundle",
  "SellOffBundleItem",
];

const SaleCommissionSource = ["PimProduct", "SupplierProgram"];

interface CommissionReportType {
  items: SalesService.SaleCommissionQueryDto[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export default function CommissionReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialParams = {
    pageNumber: parseInt(searchParams.get("pageNumber") as string) || 1,
    pageSize: parseInt(searchParams.get("pageSize") as string) || 10,
    channelCode: undefined,
    keyword: undefined,
    fromDate: undefined,
    toDate: undefined,
    orderId: undefined,
    sortBy: "ASC",
  };
  const {convertDate, convertAmount} = useConverter();
  const [isOpen, toggleDrawer] = useToggleState();
  const [loading, toggle] = useToggleState();
  const [loadingExcel, toggleExcel] = useToggleState();

  const [params, setParams] = useState<Params>(initialParams);

  const [commissionReports, setCommissionReports] =
    useState<CommissionReportType>();

  const [selectedCommission, dispatchCommission] =
    useState<SalesService.SaleCommissionQueryDto>();

  const getCommissionReports = () => {
    toggle();
    const url = URLS.GET_COMMISSION_REPORTS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        toggle();
        setCommissionReports(data);
      })
      .catch(() => toggle());
  };

  useEffect(() => {
    getCommissionReports();
  }, [params]);

  const onChangeHandler = (key: string) => {
    return (value: any) => setParams((p: any) => ({...p, [key]: value}));
  };

  const createSalesCommissionAmountType = (commission: any) => {
    const value = commission?.saleCommissionInfo?.calculationType
      ? convertAmount(commission?.saleCommissionInfo?.amount)
      : commission?.saleCommissionInfo?.amount + "%";
    return value;
  };

  const exportExcel = () => {
    toggleExcel();
    const url = URLS.EXPORT_COMMISSION_EXCEL;

    let filters = {...params};
    delete filters.pageNumber;
    delete filters.pageSize;

    ServiceApi.get(url, {
      responseType: "arraybuffer", // Ensures binary data
      params: {
        ...filters,
      },
    })
      .then(response => {
        const dirtyFileName = response.headers["content-disposition"];
        const regex =
          /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
        var fileName = dirtyFileName.match(regex)[3];
        console.log([response.data]);
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
        toggleExcel();
      })
      .catch(() => toggleExcel());
  };

  return (
    <>
      <div className="space-y-4">
        <h3 className="text-heading-3">
          <Breadcrumb />
        </h3>
        <SearchBox
          value={params?.keyword}
          onSubmit={onChangeHandler("keyword")}
          totalItems={commissionReports?.totalItems}
          disabled={loading}
        />
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <div className="col-span-1">
              <span className="text-heading-5 text-gray-700">
                <Text>salesManagment.commissionReports.createDate</Text>:
              </span>
            </div>
            <div>
              <DatePicker
                maxDate={params?.toDate ? params?.toDate : null}
                placeholderText="priceEngine.purchasePrice.selectDate"
                value={params?.fromDate}
                onChange={onChangeHandler("fromDate")}
              />
            </div>
            <div>
              <DatePicker
                minDate={params?.fromDate ? params?.fromDate : new Date()}
                placeholderText="priceEngine.purchasePrice.selectDate"
                value={params?.toDate}
                onChange={onChangeHandler("toDate")}
              />
            </div>
          </div>
          {/* <div> */}
          <Button onClick={exportExcel} loading={loadingExcel}>
            <Icon icon="file-excel" className="mr-1" />
            <Text>excel</Text>
          </Button>
          {/* </div> */}
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <span className="text-heading-5 text-gray-700">
            <Text>salesManagment.commissionReports.sortBy</Text>
          </span>
          <div className="flex gap-4 *:cursor-pointer *:rounded-xl *:px-4 *:py-2 *:text-heading-6">
            <span
              className={`p-3 text-heading-6  ${params.sortBy === "ASC" ? "bg-primary text-white" : "bg-white text-gray-500"}`}
              onClick={() => setParams(p => ({...p, sortBy: "ASC"}))}
            >
              <Text>salesManagment.commissionReports.newest</Text>
            </span>
            <span
              className={`${params.sortBy === "DESC" ? "bg-primary text-white" : "bg-white text-gray-500"}`}
              onClick={() => setParams(p => ({...p, sortBy: "DESC"}))}
            >
              <Text>salesManagment.commissionReports.oldest</Text>
            </span>
          </div>
        </div>
        {loading ? (
          [...Array(6)].map((index: number) => <Skeleton.List key={index} />)
        ) : !!commissionReports?.items?.length ? (
          <Table>
            {commissionReports?.items?.map(
              (commission: SalesService.SaleCommissionQueryDto) => {
                return (
                  <tr className="*:space-y-2 *:text-left *:text-body-2 *:text-gray-600">
                    <td>
                      <p className="text-heading-6 font-semibold text-gray-800">
                        {convertDate(commission.orderDate)}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.employeeCode
                        </Text>{" "}
                        : {commission.employeeCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.customerCode
                        </Text>{" "}
                        : {commission.customerNumber}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.channelCode
                        </Text>{" "}
                        : {commission.channelCode}
                      </p>
                    </td>
                    <td>
                      <p>
                        <Text>
                          salesManagment.commissionReports.orderNumber
                        </Text>{" "}
                        : {commission.orderNumber}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.supplierCode
                        </Text>{" "}
                        : {commission.supplierCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.lineItemType
                        </Text>{" "}
                        : {LineItemType[commission.lineItemType as number]}
                      </p>
                    </td>
                    <td>
                      <p>
                        <Text>
                          salesManagment.commissionReports.saleCommissionAmountType
                        </Text>
                        :{" "}
                        <span className={"font-medium text-success-active"}>
                          {createSalesCommissionAmountType(commission)}{" "}
                        </span>
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.secondEmployeeCode
                        </Text>
                        : {commission.secondEmployeeCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.totalSalePrice
                        </Text>{" "}
                        : {convertAmount(commission.totalSalePrice)}
                      </p>
                      <p>
                        <Text>salesManagment.commissionReports.quantity</Text> :
                        x{commission.quantity}
                      </p>
                    </td>
                    <td className="table-cell  gap-4 xl:table-cell">
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => {
                          toggleDrawer();
                          dispatchCommission(commission);
                        }}
                      >
                        <Text>global.buttons.details</Text>
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </Table>
        ) : (
          <NoItems />
        )}
        <Pagination
          totalItems={commissionReports?.totalItems}
          totalPages={commissionReports?.totalPages}
          pageNumber={params?.pageNumber || 1}
          pageSize={params?.pageSize}
          setActivePage={onChangeHandler("pageNumber")}
          onPageSizeChange={onChangeHandler("pageSize")}
        />
      </div>
      <DetailsDrawer
        isOpen={isOpen}
        toggle={toggleDrawer}
        commission={selectedCommission}
      />
    </>
  );
}
