import {
  Breadcrumb,
  Button,
  HandleName,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {withPermission} from "hoc";
import {Fragment, createContext, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";

import {config} from "constant";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";

import {SellOffProduct} from "types";
import ActiveStatusTemplate from "./List/ActiveStatusTemplate";
import PreparationStatusTemplate from "./List/PreparationStatusTemplate";
import PriceTemplate from "./List/PriceTemplate";
import SoldStatusTemplate from "./List/SoldStatusTemplate";

import ChangePhaseModal from "./sell-off-drawers/ChangePhase";
import ClosePhaseModal from "./sell-off-drawers/ClosePhase";
import FilterDrawer from "./sell-off-drawers/FilterDrawer";
import StartPreparationPhaseModal from "./sell-off-drawers/StartPrearationPhase";
import {SalesService} from "types";

export const SellOffProductsContext = createContext({
  item: {id: "", channelCode: ""},
});

function SellOffProducts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  const [data, setData] = useState<SalesService.SellOffSearchDto>();
  const initParams: SalesService.SearchSellOffRequest = {
    channelCode: null,
    keyword: null,
    searchFields: null,
    pimProductFamilyCode: null,
    supplierCode: null,
    brandCode: null,
    location: null,
    status: 99,
    needChangePhase: null,
    needClosePhase: null,
    hasFastSalesProvisionAmount: null,
    /** @format date-time */
    startedDateFrom: null,
    /** @format date-time */
    startedDateTo: null,
    /** @format date-time */
    soldDateFrom: null,
    /** @format date-time */
    soldDateTo: null,
    /** @format date-time */
    phaseChangedFrom: null,
    /** @format date-time */
    phaseChangedTo: null,
    sortType: null,
    pageNumber: parseInt(searchParams.get("pageNumber") as string) || 1,
    pageSize:
      parseInt(searchParams.get("pageSize") as string) || config.pageSize,
  };
  const [params, setParams] =
    useState<SalesService.SearchSellOffRequest>(initParams);
  const [loading, toggleLoading] = useToggleState();
  const [filterModal, setFilterModal] = useToggleState();
  const [selectedItem, setSelectedItem] = useState({
    id: "",
    channelCode: "",
  });
  const [startPrePhase, setStartPrePhase] = useToggleState();
  const [changePhase, setChangePhase] = useToggleState();
  const [closePhase, setClosePhase] = useToggleState();

  const hasData = !!data;

  const changePhaseCheckList = (item: SalesService.SellOffBasicInfoDto) => {
    setSelectedItem({
      id: item.sellOffProductId as string,
      channelCode: item?.pimProduct?.channelCode as string,
    });
    setChangePhase();
  };
  const closePhaseHandler = (item: SalesService.SellOffBasicInfoDto) => {
    setSelectedItem({
      id: item.sellOffProductId as string,
      channelCode: item?.pimProduct?.channelCode as string,
    });
    setClosePhase();
  };

  const handleSetFilterData = (
    key: keyof SalesService.SearchSellOffRequest,
  ) => {
    const pageNumber =
      key === "pageNumber"
        ? parseInt(searchParams.get("pageNumber") as string)
        : 1;
    return (value: any) => {
      if (key === "pageNumber") {
        setSearchParams({
          pageNumber: value.toString(),
          pageSize: params.pageSize ? params?.pageSize.toString() : "10",
        });
      } else if (key === "pageSize") {
        setSearchParams({
          pageNumber: params.pageNumber ? params.pageNumber.toString() : "1",
          pageSize: value.toString(),
        });
      } else {
        setSearchParams({
          pageNumber: "1",
          pageSize: params.pageSize ? params.pageSize.toString() : "10",
        });
      }
      setParams((p: any) => ({
        ...p,
        pageNumber: pageNumber,
        filter: true,
        [key]: value,
      }));
    };
  };
  const preparationCheckList = (item: SalesService.SellOffBasicInfoDto) => {
    setSelectedItem({
      id: item.sellOffProductId as string,
      channelCode: item?.pimProduct?.channelCode as string,
    });
    setStartPrePhase();
  };

  const resetFilter = () => {
    setParams(initParams);
    setKeyword("");
  };
  const getData = () => {
    toggleLoading();
    const url = URLS.SEARCH_PRODUCTS_URL;
    // const body: Params = {
    //   pageNumber: "1",
    //   pageSize: config.pageSize.toString(),
    // };
    // params.forEach((value, key) => {
    //   const isDate = [
    //     "startedDateFrom",
    //     "startedDateTo",
    //     "phaseChangedFrom",
    //     "phaseChangedTo",
    //   ].includes(key);
    //   body[key as keyof Params] = isDate
    //     ? new Date(value).toDateString()
    //     : value;
    // });
    ServiceApi.post(url, params)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        toggleLoading();
      });
  };
  const createPhaseSection = (item: SalesService.SellOffBasicInfoDto) => {
    const isSold = !!item.isSold;
    const isActive = !!item.currentPhaseDay;
    if (isSold) return <SoldStatusTemplate item={item} />;
    if (isActive) return <ActiveStatusTemplate item={item} />;
    return <PreparationStatusTemplate item={item} />;
  };

  useEffect(getData, [params]);

  return (
    <section className="space-y-4">
      <Breadcrumb />
      <SearchBox
        value={params.searchFields}
        onSubmit={handleSetFilterData("searchFields")}
        disabled={loading}
        totalItems={data?.totalItems}
        onFilter={setFilterModal}
        resetFilter={resetFilter}
        onReload={getData}
      />
      {loading ? (
        [...Array(6).keys()].map(e => <Skeleton.ImageList key={e} />)
      ) : !data?.searchResult?.length ? (
        <div className="flex w-full flex-col items-center space-y-4">
          <NoItems />
        </div>
      ) : (
        <Fragment>
          <Table>
            {data.searchResult.map((e: SalesService.SellOffBasicInfoDto) => {
              return (
                <tr key={e.sellOffProductId}>
                  <td>
                    <Link
                      to={
                        `${e.sellOffProductId}?channelCode=${e.channelCode}` as string
                      }
                      className="flex-center inline-flex gap-4"
                    >
                      <Table.Image
                        src={e.avatar?.url}
                        alt={e?.pimProduct?.originalName as string}
                        className="h-24 w-24"
                      />
                      <div className="flex flex-col gap-y-2 text-body-2 font-medium text-gray-500">
                        <h6 className="overflow-hidden text-ellipsis text-heading-6">
                          {e?.pimProduct?.originalName}
                        </h6>
                        <p>
                          <Text>
                            salesManagment.sellOffSales.sellOffProduct.articleNumber
                          </Text>{" "}
                          #{e?.pimProduct?.articleNumber}
                        </p>
                        <p>
                          <Text>
                            salesManagment.sellOffSales.sellOffProduct.channelCode
                          </Text>{" "}
                          : {e?.channelCode}
                        </p>
                        <p>
                          <Text>
                            salesManagment.sellOffSales.sellOffProduct.channelTitle
                          </Text>{" "}
                          : {e?.channelTitle}
                        </p>
                        <p>
                          <HandleName
                            translations={
                              e?.pimProduct?.productFamily?.translates
                            }
                            keyName="labelTitle"
                          />
                        </p>
                        {/* <div className="space-y-2">
                          {e?.pimProduct?.locations?.map(
                            (loc: SalesService.PimProductLocation) => (
                              <p className="text-sm">
                                <Icon
                                  icon="location-dot"
                                  size="xl"
                                  className="mr-1"
                                />{" "}
                                {[loc.name, loc.storeCode]
                                  .filter(Boolean)
                                  .join(" / ")}
                              </p>
                            ),
                          )}
                        </div> */}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <h6 className="w-fit text-left text-heading-6">
                      {createPhaseSection(e)}
                    </h6>
                  </td>
                  <td className="text-heading-6">
                    <PriceTemplate item={e} className="space-y-2" />
                  </td>
                  <td>
                    {!e.currentPhaseDay ? (
                      <Button size="sm" onClick={() => preparationCheckList(e)}>
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.startProccess
                        </Text>
                      </Button>
                    ) : !!e.changePhaseRequired ? (
                      <Button
                        size="sm"
                        variant={"warning"}
                        onClick={() => changePhaseCheckList(e)}
                      >
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.changePhase
                        </Text>
                      </Button>
                    ) : !!e.closePhaseRequired ? (
                      <Button
                        size="sm"
                        variant={"danger"}
                        onClick={() => closePhaseHandler(e)}
                      >
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.closeProccess
                        </Text>
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </Table>
          <Pagination
            totalItems={data?.totalItems}
            totalPages={data?.totalPages}
            pageNumber={params.pageNumber}
            pageSize={params.pageSize}
            setActivePage={handleSetFilterData("pageNumber")}
            onPageSizeChange={handleSetFilterData("pageSize")}
          />
        </Fragment>
      )}
      {hasData && (
        <SellOffProductsContext.Provider value={{item: selectedItem}}>
          <FilterDrawer
            isOpen={filterModal}
            data={data}
            toggle={setFilterModal}
            setParams={setParams}
            keyword={keyword}
            setKeyword={setKeyword}
            resetFilter={resetFilter}
            params={params}
          />
          <StartPreparationPhaseModal
            isOpen={startPrePhase}
            toggle={setStartPrePhase}
            getData={getData}
          />
          <ChangePhaseModal
            isOpen={changePhase}
            toggle={setChangePhase}
            getData={getData}
          />
          <ClosePhaseModal
            isOpen={closePhase}
            toggle={setClosePhase}
            getData={getData}
            id={selectedItem?.id}
          />
        </SellOffProductsContext.Provider>
      )}
    </section>
  );
}

export default withPermission(SellOffProducts, ["PS_GetSellOffProduc"]);
